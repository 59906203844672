<template>
  <teleport to="body">
    <Transition :name="transitionName" :css="animated">
      <div
        v-if="modelValue"
        class="sharedModal sharedModal__overlay"
        :class="[props.class, overlayClasses]"
        @click.self="overlayClick()"
      >
        <div class="sharedModal__modal_outer" :class="modalOuterClasses" :style="modalOuterStyles">
          <slot name="outerHeader" :close="closeModal"></slot>
          <div class="sharedModal__modal" :style="modalInnerStyles">
            <div v-if="!hideCloseButton" class="sharedModal__modal_close" @click="closeModal()">
              <LazySvgoCloseXIcon :style="modalCloseIconStyles" />
            </div>
            <slot :close="closeModal"></slot>
            <slot name="modalFooter" :close="closeModal">
              <div v-if="footerButton" class="sharedModal__modal_footer">
                <SharedButton @click="closeModal(footerButton?.onClick)">{{ footerButton.label }}</SharedButton>
              </div>
            </slot>
          </div>
          <slot name="outerFooter" :close="closeModal"></slot>
        </div>
      </div>
    </Transition>
  </teleport>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs, watch } from 'vue';

import type { ISharedModalEmits, ISharedModalProps } from '~/components/SharedModal/SharedModal.types';
import { Colors } from '~/constants/colors.constants';

const COMPONENT_NAME = 'sharedModal';
const PREFIX_COMPONENT_NAME = 'shared_modal';

const emits = defineEmits<ISharedModalEmits>();

const props = withDefaults(defineProps<ISharedModalProps>(), {
  align: 'center',
  animated: true,
  appearance: 'opacity',
  class: '',
  disableCloseOnOverlayClick: false,
  disableScroll: true,
  escClose: false,
  fillIconClose: Colors.NEUTRAL.WHITE,
  fullScreen: false,
  fullWidth: false,
  hideCloseButton: false,
  hideOverlay: false,
});

const {
  modelValue,
  disableCloseOnOverlayClick,
  hideOverlay,
  disableScroll,
  radius,
  footerButton,
  hideCloseButton,
  fullScreen,
  margin,
  padding,
  align,
  appearance,
  animated,
  fillIconClose,
  fullWidth,
} = toRefs(props);

const transitionName = computed(() => `${PREFIX_COMPONENT_NAME}-${appearance.value}`);

const overlayClasses = computed(() => ({
  [`${COMPONENT_NAME}__overlay--hidden`]: hideOverlay.value,
  [`${COMPONENT_NAME}__overlay--topped`]: align.value === 'top',
  [`${COMPONENT_NAME}__overlay--centered`]: align.value === 'center',
  [`${COMPONENT_NAME}__overlay--bottomed`]: align.value === 'bottom',
}));

const modalOuterClasses = computed(() => ({
  [`${COMPONENT_NAME}__modal_outer--fullscreen`]: fullScreen.value,
  [`${COMPONENT_NAME}__modal_outer--fullwidth`]: fullWidth.value,
}));

const modalOuterStyles = computed(() => {
  const properties: CSSProperties = {};

  if (radius?.value) properties.borderRadius = GlobalUtils.CSS.proceedCssValue(radius.value);
  if (margin?.value) properties.margin = margin.value;
  if (padding?.value) properties.padding = padding.value;

  return properties;
});

const modalCloseIconStyles = computed(() => {
  const properties: CSSProperties = {};

  properties.fill = fillIconClose.value;

  return properties;
});

const modalInnerStyles = computed(() => {
  const properties: CSSProperties = {};

  if (radius?.value) properties.borderRadius = GlobalUtils.CSS.proceedCssValue(radius.value);
  return properties;
});

const overlayClick = () => {
  !disableCloseOnOverlayClick.value && closeModal();
  emits('onOverlayClick');
};

const closeModal = (callback?: () => void) => {
  emits('update:modelValue', false);
  emits('onClose');
  callback && typeof callback === 'function' && callback();
};

props.escClose &&
  useWindowKeyboard('Escape', () => {
    closeModal();
  });

watch(
  () => modelValue.value,
  (isOpened) => {
    if (!document) return;
    if (disableScroll.value && isOpened) {
      document.body.classList.add('shared-modal-opened');
    } else {
      document.body.classList.remove('shared-modal-opened');
    }
  },
);

onUnmounted(() => {
  document.body.classList.remove('shared-modal-opened');
});
</script>

<style src="./SharedModal.scss" scoped lang="scss"></style>
